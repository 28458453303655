var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"main-panel"}},[_c('div',{key:"measure",staticClass:"measure-form f-col light"},[_c('v-tabs-header',{attrs:{"items":_vm.tabsItems},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('v-tabs',{staticClass:"mt-1",attrs:{"items":_vm.tabsItems},scopedSlots:_vm._u([{key:"location",fn:function(){return [_c('div',{staticClass:"f-row"},[_c('div',{staticClass:"field f-col"},[_c('translate',{staticClass:"label"},[_vm._v("1st coordinate")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.location.coord1))])],1),_c('div',{staticClass:"field f-col"},[_c('translate',{staticClass:"label"},[_vm._v("2nd coordinate")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.location.coord2))])],1),_c('v-menu',{attrs:{"aria-label":_vm.tr.Menu,"transition":"slide-y","align":"rr;bb,tt","items":_vm.locationMenuItems},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"icon small",attrs:{"aria-label":_vm.tr.Menu},on:{"click":toggle}},[_c('v-icon',{attrs:{"name":"menu-dots"}})],1)]}}])})],1)]},proxy:true},{key:"distance",fn:function(){return [_c('div',{staticClass:"f-row"},[_c('div',{staticClass:"field f-col"},[_c('translate',{staticClass:"label"},[_vm._v("Last segment")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.distance.lastSegment))])],1),_c('div',{staticClass:"field f-col"},[_c('translate',{staticClass:"label"},[_vm._v("Total length")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.distance.total))])],1),_c('v-menu',{attrs:{"aria-label":_vm.tr.Menu,"transition":"slide-y","align":"rr;bb,tt","items":_vm.distanceMenuItems},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"icon small",attrs:{"aria-label":_vm.tr.Menu},on:{"click":toggle}},[_c('v-icon',{attrs:{"name":"menu-dots"}})],1)]}},{key:"item-prepend(group-check)",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"m-2",attrs:{"name":item.checked ? 'check' : ''}})]}},{key:"item(check)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"f-grow",staticStyle:{"margin-left":"24px"},domProps:{"textContent":_vm._s(item.text)}}),_c('v-icon',{staticClass:"p-2",attrs:{"name":item.checked ? 'dot' : ''}})]}}])})],1)]},proxy:true},{key:"area",fn:function(){return [_c('div',{staticClass:"f-row"},[_c('div',{staticClass:"field f-col"},[_c('translate',{staticClass:"label"},[_vm._v("Perimeter")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.area.perimeter))])],1),_c('div',{staticClass:"field f-col"},[_c('translate',{staticClass:"label"},[_vm._v("Area")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.area.area))])],1),_c('v-menu',{attrs:{"aria-label":_vm.tr.Menu,"transition":"slide-y","align":"rr;bb,tt","items":_vm.areaMenuItems},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"icon small",attrs:{"aria-label":_vm.tr.Menu},on:{"click":toggle}},[_c('v-icon',{attrs:{"name":"menu-dots"}})],1)]}},{key:"item-prepend(group-check)",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"m-2",attrs:{"name":item.checked ? 'check' : ''}})]}},{key:"item(check)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"f-grow",staticStyle:{"margin-left":"24px"},domProps:{"textContent":_vm._s(item.text)}}),_c('v-icon',{staticClass:"p-2",attrs:{"name":item.checked ? 'dot' : ''}})]}}])})],1)]},proxy:true}]),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }